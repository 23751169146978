import { css  } from '@emotion/core'
import { mq, variables } from '../../cssInJs'

export default {
  top: css(mq({
    //height: [44, null, 80],
    height: [70, null, 100],
    background: variables.darkGrayColor,
    position: 'fixed',
    width: '100%',
    zIndex: '999',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 0.3s'
  })),
  topScrolled: css(mq({
    height: [44, null, 56],
  })),
  logo: css(mq({
  })),
  logoImg: css(mq({
    display: 'block',
    height: [50, null, 72],
    transition: 'all 0.3s'
  })),
  logoScrolled: css(mq({
    height: [22, null, 36],
  })),
}